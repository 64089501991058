import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import i18next from '../i18n/config';
import { BeginPay } from '../types/beginPay.types';
import { VerticalType } from '../types/enums';
import axios from './axios';

const t: TFunction = i18next.t.bind(i18next);

const beginPay = async (beginPayValues: BeginPay) => {
  try {
    const res = await axios.post(
      `/verticals/${VerticalType[beginPayValues.verticalType]}/commerce/${beginPayValues.commerceId}/payment`,
      beginPayValues,
    );

    return res.data.data.gatewayUrl;
  } catch (err) {
    const axiosError = err as AxiosError;
    const errorMessage = axiosError.response?.data?.showMessage?.ES || t('errors.generic-in-payment');

    return { error: errorMessage };
  }
};

export const paymentService = { beginPay };
